.rmsc {
  --rmsc-main: #fff !important;
  --rmsc-border: #000;
}

/* index.css */
html {
  scroll-behavior: smooth;
}
* {
  font-family: "Poppins";
}
body {
  margin: 0;
  padding: 0;
}
.rs-picker-daterange.rs-picker-subtle .rs-input-group,
.location-search-input,
.dropdown-container {
  /* border: 1px solid #c6c6c6 !important; */
  border: none !important;
  outline: none !important;
  border-radius: 8px !important;
  min-height: 42px !important;
  width: 100% !important;
}
.rs-input-group-focus {
  outline: none !important;
}
.rs-input-group .rs-input-group-inside {
}

.rs-input-group .rs-input-group-inside > .rs-input-group-addon {
  display: none !important;
}
.rs-picker-error > .rs-input-group {
  border: transparent !important;
}

.locked-dates th {
  width: 50%;
}

.boat-types-admin label:hover {
  background: red !important;
  border: red 2px solid !important;
}
.cs-bg-orange {
  background-color: #ddb467;
  border-color: #ddb467;
  color: #000;
}

.cs-bg-orange:hover {
  background-color: #aa6e00;
  border-color: #aa6e00;
  color: #fff;
}

.payments-data-table table tr th:first-child,
.payments-data-table table tr td:first-child {
  display: none;
}

.locked-dates .width-data-table {
  width: 100%;
}

.page-no {
  display: none !important;
}
.gZmPOF {
  align-items: center !important;
  justify-content: center !important;
}
.sidebar {
  height: 100vh;
  background-color: #f6f6f6;
  padding-top: 0 20px;
  overflow-y: auto;
  transition: width 0.3s;
}

.active-link {
  color: orange;
  font-weight: 800;
}

.rdrMonth {
  width: 100% !important;
}

.booking-labels-info {
  padding: "0 13px";
}

.review-time {
  font-size: 14px !important;
  color: #adadad !important;
}

.slick-dots li button:before {
  color: #fff !important;
}

.reviews-container {
  padding: 0 40px 0 10px;
}
.review-item {
  padding: 25px;
  background-color: #2c2c2c;
  border-radius: 8px;
}
.btn-cs-p {
  padding: 8px 6px;
  font-size: 12px;
}
.fw-cs {
  font-weight: 100;
}

@media only screen and (max-width: 767px) {
  .booking-labels-info {
    padding: "0 0";
  }
  .how-to-video {
    width: 100%;
  }
  .send-btn {
    width: 100%;
    margin-top: 11px;
    padding: 12px;
  }
  .margin-top-mobile {
    margin-top: 20px;
  }
  .margin-top-mobile-40 {
    margin-top: 40px;
  }
  .mobile-direction-column {
    flex-direction: column-reverse;
  }
  .mobile-direction-column-only {
    flex-direction: column;
  }
  .margin-top-mobile-10 {
    margin-top: 10px;
  }
  .rdrMonth {
    width: 100% !important;
  }
  .image-preview {
    width: 47%;
    height: 100%;
  }
  .padding-mobile-0 {
    padding: 10px !important;
  }

  .color-box {
    font-size: 13px;
  }
  .listing-container-mobile {
    flex-direction: column-reverse;
  }
  .inqueryBox {
    margin-bottom: 35px !important;
  }
  .responsive-table {
    font-size: 14px; /* Adjust as needed */
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* Change the background color of the switcher dot */
.custom-switch:checked {
  background-color: #000 !important; /* Background color of the dot */
}

/* Optional: Customize the appearance of the switcher when unchecked */
.custom-switch {
  appearance: none; /* Remove default styles */
  -webkit-appearance: none; /* For Safari */
  background-color: white; /* Background when unchecked */
  border: 1px solid black;
  border-radius: 1rem;
  width: 4em !important;
  height: 1.8rem;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-switch:before {
  content: "";
  position: absolute;
  top: 1.7px !important;
  left: 2px !important;
  width: 1.4rem;
  height: 1.4rem;
  background-color: #000; /* Dot color */
  border-radius: 50%;
  transition: transform 0.3s;
}

.custom-switch:checked:before {
  transform: translateX(2rem); /* Moves the dot to the right */
  background-color: #fff;
}

.labels-wrapper {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.color-box {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  border-radius: 8px;
  padding: 0.8rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.sidebar a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar a:hover {
  background-color: #ebebeb;
}

.sidebar .active {
  background-color: #ebebeb;
  border: none;
}

.sidebar .icon {
  font-size: 18px;
}

#page-content-wrapper {
  transition: margin-left 0.3s;
}

.main-content {
  padding: 20px;
}

.header {
  background-color: #f6f6f6;
  color: white;
  padding: 10px 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 80px;
  }

  .sidebar a span {
    display: none;
  }

  .sidebar .icon {
    margin-right: 0;
  }

  #page-content-wrapper {
    margin-left: 80px;
  }
}

/* In your CSS file */
.no-gutter-row [class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.image-gallery {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between images as needed */
}

.image-preview {
  width: 32.33%;
  height: 250px;
  object-fit: cover; /* or "contain" depending on your preference */
}

ul.ks-cboxtags {
  list-style: none;
  padding: 0px 0px 20px 0px;
}
ul.ks-cboxtags li {
  display: inline;
  padding: 0 5px;
}
ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.boat-types-admin li input[type="checkbox"]:hover + label::before {
  content: "\f1f8";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid #1a1a1a;
  background-color: #1a1a1a;
  color: #fff;
  transition: all 0.2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 2px solid #1a1a1a;
}

.productCard {
  transition: 0.3s ease-in;
  background-size: cover;
}

.offerButton {
  margin-top: -9px !important;
  width: 150px;
}

/* .productCard:hover{
  background-size: 105% !important;
} */

.add-remove {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.inquiry-label {
  text-align: center;
  margin-bottom: -14px;
}

.p-tag-inquiry {
  margin: 0px !important;
  font-size: 13px;
}

.chat {
  height: 450px;
  overflow-y: scroll;
}

.chat .chatBubble {
  display: flex;
}

.chat .chatBubble.received {
  justify-content: start;
}

.slider-container {
  padding: 0;
}

.slider-image > img,
.slick-slide > img {
  height: 320px !important;
  aspect-ratio: 1/1 !important;
  object-fit: cover !important;
}

.mailbox-open-content {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100%;
  overflow-y: scroll;
}

.mailbox-open-content::-webkit-scrollbar {
  display: none;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.additional-box {
  width: 90%;
  padding: 14px;
  border: 1px solid #e3e6ea;
  border-radius: 8px;
}

.chat .chatBubble p {
  padding: 6px 16px;
  max-width: 80%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.chat .chatBubble p {
  padding: 6px 16px;
  border-radius: 10px;
}

.chat .chatBubble.received p {
  background-color: #1a1a1a;
  color: #f6f6f6;
}

.chat .chatBubble.sent p {
  background-color: #f6f6f6;
  color: #1a1a1a;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #7cc5d9;
}
.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

/* chat component */
.messages-wrapper {
  padding: 30px;
  margin-bottom: 60px;
}
.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 15px;
  background-color: #7cc5d9;
  color: #1c2c4c;
  width: max-content;
  max-width: calc(100% - 50px);
  box-shadow: -2px 2px 1px 1px #4c768d;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  background-color: #fff;
  box-shadow: -2px 2px 1px 1px #88dded;
}
.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1c2c4c;
}
.user-message {
  word-break: break-all;
}
.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-color: #4c768d;
  display: flex;
}
.send-message > input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: white;
  color: #1c2c4c;
  font-size: 1rem;
}
.send-message > input:placeholder {
  color: #ddd;
}
.send-message > :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #7cc5d9;
}
.send-message > button {
  width: 70px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  color: #242443;
  border: 1px solid #7cc5d9;
  background-color: #7cc5d9;
  font-weight: 600;
}

.thumbnailImg {
  width: 100%;
}

.header {
  color: #f6f6f6 !important;
}

.header a {
  text-decoration: none;
}

.header .cs-tx-light,
.header .cs-tx-light .dropdown-toggle {
  color: #f6f6f6 !important;
}

.dropdown-menu {
  display: none;
  transition: all 0.3s ease;
}

.dropdown-menu.show {
  display: block;
  margin-top: 0;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .navbar .dropdown-menu.show {
    margin-top: 10px !important;
  }
}

.navbar-collapse .dropdown-menu {
  position: static;
  float: none;
  width: 100%;
  background-color: #f8f9fa;
  margin-top: 0;
}

.social-icons {
  display: flex;
}

.social-icons a {
  margin-right: 10px;
  color: #f6f6f6; /* Adjust the color as needed */
  font-size: 20px; /* Adjust the font size as needed */
  background-color: #ddb467;
  padding: 0px 6px 2px 6px;
  border-radius: 5px;
  transition: 0.3s ease;
}

.social-icons a:hover {
  color: #ddb467; /* Adjust the hover color as needed */
  background-color: #f6f6f6;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-container img {
  animation: fadeInOut 2s ease-in-out infinite; /* Adjust the duration as needed */
}

.app-menu > ul > li > a.active span,
.sub-menu .active span,
.app-header .active span {
  color: #fff !important;
  background: #1a1a1a;
  padding: 6px 8px 6px 8px;
  border-radius: 6px;
}

.nav-item a,
.nav-item a span {
  text-decoration: none;
  transition: 0.3s ease;
  color: black;
}

.nav-item a:hover {
  color: #000;
}

.navbar-toggler,
.navbar-toggler-icon {
  filter: invert(1);
}

.app-menu > ul > li > a span {
  transition: 0.3s ease;
}

.app-menu > ul > li > a.active i {
  color: #fff;
}

.sub-menu {
  padding: 5px;
}

.sub-menu li a {
  margin: 0 5px;
  border-radius: 4px;
}

.cs-bg-dark {
  background-color: #121212 !important;
}

.cs-bg-dark2 {
  background-color: #333 !important;
  border-radius: 10px;
}

.cs-bg-light {
  background-color: #efefef !important;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 17px;
}

.heroSection {
  height: 90vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #2c2c2c;
}

@media only screen and (max-width: 767px) {
  .heroSection {
    height: 40vh;
  }
}

.heroVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
  z-index: 9 !important;
}

.heroContent {
  z-index: 999;
}

.overlay {
  width: 100vw;
  margin-bottom: -1px;
}

.cs-section {
  padding: 60px 10% !important;
}

.statsCol {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 40px 30px 40px 30px;
}

.floating-btn {
  position: fixed;
  bottom: 10px;
  width: 100%;
  z-index: 999999;
  display: flex;
  justify-content: center;
}

.bg-cs-secondary {
  background-color: #202020;
}

.br-1 {
  border-right: 1px solid #fff;
}

.statsSection {
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  background-attachment: fixed;
  background-image: url("https://bbrboatrentals.com/assets/frontend/images/statsBg.webp");
  background-size: cover;
  background-position: center;
}

.statsSection::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the last value (0.5) for the desired transparency */
}

.userTypeSection {
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  background-attachment: fixed;
  background-image: url("https://bbrboatrentals.com/assets/frontend/images/bbrboat.jpg");
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  align-items: center;
}

.userTypeSection::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the last value (0.5) for the desired transparency */
}

.statsCol {
  z-index: 2;
}

.cs-bg-2 {
  background-image: url("https://bbrboatrentals.com/assets/uploads/sites/153/2023/03/boats-and-luxury-yachts-in-transparent-sea-at-suns-2021-08-26-17-01-00-utc.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.cs-section-2 {
  padding: 60px 10% 60px 60px;
}

/* Add your custom styles to prevent overlapping */
.slider-container {
  margin: 0 auto; /* Center the slider */
}

.slick-slide img {
  width: 100%;
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #000; /* Customize arrow color */
}

.slick-dots {
  bottom: 10px; /* Adjust dots position */
}

.slick-dots li button:before {
  font-size: 10px; /* Adjust dot size */
}

.fs-10 {
  font-size: 10px;
}

.testimonialMessage {
  font-size: 12px;
  line-height: 22px;
}

.aboutText {
  font-size: 12px;
}

.inqueryBox {
  position: sticky;
  top: 20px;
}

.payoutRow {
  border-bottom: 1px solid #e7e7e7;
}

.payout {
  background-color: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #ececec;
}

.sigCanvas {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}

#frontEnd h1,
#frontEnd h2,
#frontEnd h3,
#frontEnd h4,
#frontEnd h5,
#frontEnd h6,
#frontEnd a {
  font-family: "Fjalla One", sans-serif;
}

.cs-nav {
  width: 100%;
  justify-content: center;
  gap: 18px;
}

.cs-nav a {
  font-size: 20px;
}

#frontEnd p,
#frontEnd li {
  font-size: 16px;
}

.accordion-item .accordion-button,
.accordion-item .accordion-button:active,
.accordion-item .accordion-button:focus {
  background-color: #272727 !important;
  color: #fff;
}
.accordion-item {
  background-color: #333;
  color: #fff;
}

.accordion-item .accordion-button.collapsed {
  border-bottom: 1px solid #fff;
}

.accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  border: 1px solid #fff;
}

.accordion-header button {
  font-size: 18px !important;
  border: 1px solid #fff;
}
.accordion-button::after {
  filter: contrast(0.5) saturate(2);
}

.testimonialMessage {
  font-size: 14px !important;
}

.listingCal .rbc-btn-group button {
  background-color: #c7c7c7;
  border: 1px solid #1a1a1a;
}

.navbar-dark .navbar-nav .nav-link {
  text-align: center;
}

.listingCal .rbc-off-range {
  color: #000;
}

.text-cs-dark {
  color: #1a1a1a;
}

#contact .react-tel-input .form-control {
  width: 100% !important;
  height: 45px;
}

.userTypeSection .icon {
  margin-right: 8px;
}
.userTypeSection .btn {
  font-size: 18px;
}

.mobile-logo,
.mobile-only {
  display: none !important;
}

.footer-phone {
  justify-content: center;
}

.footer-email,
.footer-socials {
  justify-content: end;
}

.privacy-policy a {
  color: #fff;
}

.dateRangePicker {
  z-index: 9 !important;
}
.boatListings {
  z-index: 5 !important;
}

.filter-card {
  height: 400px;
  overflow-y: scroll;
}

.filter-card::-webkit-scrollbar {
  width: 8px;
  transition: 0.3s ease;
}
.listing-image-waiver {
  width: 400px;
  border-radius: 14px;
  height: 250px;
  aspect-ratio: 3/4;
  object-fit: cover;
}
/* Track */
.filter-card::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.filter-card::-webkit-scrollbar-thumb {
  background: #d8d8d8;
}

/* Handle on hover */
.filter-card::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-link {
  color: #ddb467;
}
.text-link:hover {
  color: #ebd09e;
}
.pricing-text {
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .pricing-text {
    width: 100%;
  }
  .booking-modal-btns-mobile {
    position: relative;
    bottom: 6rem;
  }
  .how-to-title {
    font-size: 16px;
  }
  .how-to-videos-wrapper {
    padding-bottom: 55% !important;
  }
  .how-to-videos {
    width: 100% !important;
    height: 200px !important;
  }
  .cs-section {
    padding: 60px 20px !important;
  }
  .productCard {
    margin-top: 30px !important;
  }
  .listing-image-waiver {
    width: 100% !important;
  }
  .br-1 {
    border-right: 0px !important;
    border-bottom: 1px solid #fff !important;
  }
  .footer-logo {
    width: 150px;
  }
  .filter-card {
    height: 200px;
    overflow-y: scroll;
  }
  .app-auth-sign-up .app-auth-background {
    display: none;
  }
  .app-auth-container {
    width: 100%;
  }
  .app-auth-container .logo a {
    display: none;
  }
  .auth-description {
    font-size: 12px !important;
  }
  .mobile-logo {
    display: flex !important;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .mobile-logo h1 {
    font-weight: 700;
    font-family: "Fjalla One", sans-serif;
  }
  .react-tel-input .form-control {
    width: 140px !important;
  }
  .app-header .container-fluid {
    flex-direction: column;
    padding: 0;
    width: 100% !important;
  }
  .app-header .container-fluid .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .app-header .navbar {
    margin-left: 50px;
    .navbar-nav {
      align-items: flex-start;
      gap: 15px;
      /* padding-top: 20px; */
    }
  }
  .app-sidebar .logo,
  .app.menu-off-canvas .app-sidebar .logo,
  .app.sidebar-hidden .app-sidebar .logo.hidden-sidebar-logo {
    width: 150px;
  }
  .app-content {
    padding-bottom: 20px;
  }
  .row .sender {
    margin-top: 20px;
  }
  .rbc-event-content {
    font-size: 10px;
  }
  .rbc-show-more {
    font-size: 8px !important;
  }
  .cal-body {
    padding: 20px 15px !important;
  }
  .payoutRow svg {
    width: 150px;
  }
  .mobile-only {
    display: block !important;
  }
  .cs-footer .text-reset {
    font-size: 14px;
    text-decoration: none;
  }
  .footer-phone,
  .footer-socials,
  .footer-email {
    justify-content: center;
  }
  .footer-contact {
    gap: 20px;
  }
  #frontEnd .navbar-nav {
    align-items: flex-start;
    gap: 15px;
    padding-top: 20px;
  }
  .navbar-nav .nav-link {
    margin: 0 !important;
    padding: 0 !important;
  }
  .navbar-collapse.show {
    transition: 0.3s ease;
    padding-bottom: 20px;
  }
  .header .social-icons {
    margin-top: 20px;
  }
  .partners-brands {
    gap: 20px;
  }
  .home-about-img {
    padding: 0;
  }
  .icon-col {
    display: none !important;
  }
  .stats-heading {
    font-size: 14px;
  }
  .neil-img {
    width: 100%;
  }
  .search-row .col-6 {
    gap: 0px;
  }
  .app-content .page-description {
    padding: 30px 0;
    gap: 10px;
    display: flex !important;
    flex-direction: column;
  }
  .reviews-container {
    padding: 0px 10px !important;
  }
  .reviews-home {
    margin-bottom: 40px;
  }
  .app-sidebar {
    height: 100vh !important;
    padding-bottom: 10vh;
  }
  .app-sidebar .app-menu {
    overflow: scroll;
  }
  .mobile-mt {
    margin-top: 20px;
  }
  .boats-container {
    padding: 0 !important;
  }
}

.inquiryAvatar {
  width: 40px;
  border-radius: 50px;
}

.waivers-body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.waivers-body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

.waivers-body::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

.waivers-body::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

.waivers-body::-webkit-scrollbar-thumb {
  border-radius: 19px;
  background-color: #b9c0c7;
}

.waivers-body::-webkit-scrollbar-thumb:hover {
  background-color: #13200f;
}

.waivers-body::-webkit-scrollbar-thumb:active {
  background-color: #0c150a;
}

.width-100 > div:first-child {
  height: 400px !important;
  width: 100% !important;
  position: relative !important;
}

.rs-picker-popup {
  z-index: 9999999 !important;
}

.close-icon:hover {
  cursor: pointer;
}

.offerButton,
.send-inquiry {
  background: rgb(97, 97, 252);
  border-color: rgb(97, 97, 252);
}

.user-status {
  background-color: black;
  border-radius: 8px;
  color: white;
  padding: 3px 7px;
  font-size: 12px;
}
.hero-img {
  width: 60%;
}

.single-listing-calendar .rbc-today {
  background-color: #1e599c !important;
}

.single-listing-calendar .rbc-current .rbc-button-link,
.single-listing-calendar .rbc-off-range .rbc-button-link {
  color: white !important;
}

.single-listing-calendar .rbc-off-range-bg {
  background-color: #ff9500 !important;
}

.rs-input-group:focus-within {
  outline: none !important;
}
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: none !important;
}

.rmsc .dropdown-container:focus {
  border: none !important;
  border-color: transparent !important;
}

.brtr {
  border-top-right-radius: 8px !important;
}
.brbr {
  border-bottom-right-radius: 8px !important;
}

.brtl {
  border-top-left-radius: 8px !important;
}
.brbl {
  border-bottom-left-radius: 8px !important;
}

@media (max-width: 768px) {
  .chatbot-wrapper {
    right: 0 !important;
  }
  .chatbot-wrapper .card {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .brtr {
    border-top-right-radius: 0px !important;
  }
  .brbr {
    border-bottom-right-radius: 0px !important;
  }
  .brtl {
    border-top-left-radius: 0px !important;
  }
  .brbl {
    border-bottom-left-radius: 0px !important;
  }
}

:where(.css-1s2u09g-control) {
  width: 100% !important;
}
.rmsc:focus {
  outline: none !important;
  border: none;
  border-color: transparent;
}
.dropdown-container:focus {
  outline: none !important;
  border: none !important;
  border-color: transparent;
}

@media (min-width: 768px) {
  .custom-width {
    width: 100px !important;
  }
}
@media (min-width: 1024px) {
  .custom-width {
    width: 100% !important;
  }
}
.location-search-input::placeholder {
  color: #191919 !important;
  font-size: 1rem !important;
}
#datePicker::placeholder {
  color: #191919 !important;
  font-size: 1rem !important;
}
.rs-input-group > input {
  height: 42px !important;
}
.dropdown-content {
  min-width: 200px !important;
  z-index: 99 !important;
  background-color: #fff !important;
}

.select-item,
.selected {
  background-color: #fff !important;
}

.select-box {
  width: 100%;
  max-width: 250px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .select-box {
    width: 100%;
    max-width: 185px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
@media (min-width: 1441px) {
  .select-box {
    width: 100%;
    max-width: 205px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

@media (max-width: 1025px) {
  .select-box {
    width: 100%;
    max-width: 140px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

@media (max-width: 425px) {
  .select-box {
    width: 100%;
    max-width: 266px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

@media (max-width: 375px) {
  .select-box {
    width: 100%;
    max-width: 216px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

@media (max-width: 320px) {
  .select-box {
    width: 100%;
    max-width: 162px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

ul[class="MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list"][role="listbox"] {
  position: relative !important;
  display: block !important;
  overflow: scroll !important;
  height: 224px !important;
}
div[class="MuiBox-root css-0"][role="option"] {
  height: 100% !important;
  overflow: scroll;
  padding-bottom: 3rem !important;
}
svg[class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"][data-testid="ArrowDropDownIcon"] {
  position: absolute !important;
  right: 20px;
}
